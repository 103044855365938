<template>
  <div>
    <v-container>
      <template v-if="clientView">
        <!-- Visualização do cliente -->
        <v-card class="mb-4">
          <v-card-title>
            <v-btn color="error" @click="clientView=null" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <span class="headline">Cliente</span>
            <v-spacer></v-spacer>
            <v-btn color="secondary" outlined @click="openDialog(clientView.client)">Editar Informações</v-btn>
          </v-card-title>
          <v-card-text>
            <p><strong>ID:</strong> {{ clientView.client.id }}</p>
            <p><strong>Nome:</strong> {{ clientView.client.name }} {{ clientView.client.lastname }}</p>
            <p><strong>Email:</strong> {{ clientView.client.email }}</p>
            <p><strong>Telefone:</strong> {{ clientView.client.phone }}</p>
            <p><strong>Endereço:</strong> {{ clientView.client.address_street }}, {{ clientView.client.address_number }}, {{ clientView.client.postal_code }}</p>
          </v-card-text>
        </v-card>
        <SignatureCrud :aplication="aplication" :signatures="clientView.signatures" :client_id="clientView.client.id" @reactRequest="reactRequest" @setLoading="setLoading" />
        <ChargeCrud :aplication="aplication" :charges="clientView.charges" :client_id="clientView.client.id" @reactRequest="reactRequest" @setLoading="setLoading" />
      </template>
      <template v-else>
        <div class="text-right">
          <v-btn color="primary" @click="openDialog()">Cadastrar Novo Cliente</v-btn>
        </div>
        <v-data-table
          :headers="headers"
          :items="clients"
          class="elevation-1"
          hide-default-footer
          @click:row="openItem"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="openDialog(item)">mdi-pencil</v-icon>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination v-model="page" :length="totalPages" @input="fetchClients"></v-pagination>
        </div>
      </template>
    </v-container>
    <!-- Diálogo de cadastro e edição de cliente -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ editingClient ? 'Editar Cliente' : 'Novo Cliente' }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="clientForm" v-model="formValid">
            <v-text-field label="Nome" v-model="client.name" :rules="[rules.required]"></v-text-field>
            <v-text-field label="Sobrenome" v-model="client.lastname" :rules="[rules.required]"></v-text-field>
            <v-text-field label="CPF ou CNPJ" v-model="client.document" :rules="[rules.required]"></v-text-field>
            <v-text-field label="Data de Nascimento" type="date" v-model="client.birthdate" :rules="[rules.required]"></v-text-field>
            <v-text-field label="Email" v-model="client.email" :rules="[rules.required, rules.email]"></v-text-field>
            <v-text-field label="Telefone" v-model="client.phone" :rules="[rules.required]"></v-text-field>
            <v-text-field label="CEP" v-model="client.postal_code" :rules="[rules.required]" @change="fetchAddress"></v-text-field>
            <v-text-field label="Endereço" v-model="client.address_street" :rules="[rules.required]" :readonly="addressField.block"></v-text-field>
            <v-text-field label="Número" v-model="client.address_number" :rules="[rules.required]"></v-text-field>
            <v-text-field label="Complemento" v-model="client.address_complement"></v-text-field>
            <v-text-field label="Bairro" v-model="client.address_neighborhood" :rules="[rules.required]" :readonly="addressField.block"></v-text-field>
            <v-text-field label="Cidade" v-model="client.address_city" :rules="[rules.required]" :readonly="addressField.block"></v-text-field>
            <v-text-field label="UF" v-model="client.address_state" :rules="[rules.required]" :readonly="addressField.block"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="saveClient">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import SignatureCrud from './SignatureCrud.vue';
import ChargeCrud from './ChargeCrud.vue';

export default {
  components: { ChargeCrud, SignatureCrud },
  name: 'ClientsPage',
  props: ['aplication', 'data'],
  data() {
    return {
      clientView: null,
      clients: [],
      totalPages: 1,
      page: 1,
      dialog: false,
      editingClient: null,
      client: this.getEmptyClient(),
      headers: [
        { text: 'Nome', value: 'name', sortable: false },
        { text: 'Sobrenome', value: 'lastname', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Telefone', value: 'phone', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      rules: {
        required: (value) => !!value || 'Campo obrigatório',
        email: (value) => /.+@.+\..+/.test(value) || 'Email inválido',
      },
      formValid: false,
      addressField: {
          block: false,
          loader: false
      }
    };
  },
  methods: {
    setLoading(value){
      this.$emit('setLoading',value);
    },
    reactRequest(value){
      this.$emit('reactRequest',value);
    },
    async post(endpoint = "/", data) {
      try {
        this.$emit('setLoading', true);
        const response = await axios.post(this.aplication.baseUrlApi + endpoint, data, {
          headers: { Authorization: this.aplication.authToken, 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        this.$emit('setLoading',false);
        this.$emit('reactRequest', response.data);
        return response.data;
      } catch (error) {
        this.$emit('setLoading',false);
        if (error.response && error.response.data && error.response.data.errors) {
          this.$emit('reactRequest',error.response.data);
        }
      }
    },
    async get(endpoint = "/") {
      try {
        this.$emit('setLoading',true);
        const response = await axios({
          url: this.aplication.baseUrlApi + endpoint,
          method: "GET",
          headers: {
            'Authorization': this.aplication.authToken,
          }
        });
        this.$emit('reactRequest',response.data);
        this.$emit('setLoading',false);
        return response.data;
      } catch (error) {
        this.$emit('setLoading',false);
        if (error.response && error.response.data && error.response.data.errors) {
          this.$emit('reactRequest',error.response.data);
        }
      }
    },
    fetchAddress() {
        this.addressField.block = false;
        this.addressField.loader = true;
        axios
          .get(`https://viacep.com.br/ws/${this.client.postal_code}/json/`)
          .then((response) => {
            if (response.data) {
              this.client.address_street = response.data.logradouro || '';
              this.client.address_neighborhood = response.data.bairro || '';
              this.client.address_city = response.data.localidade || '';
              this.client.address_state = response.data.uf || '';
              this.addressField.block = true;
            }
            this.addressField.loader = false;
          })
          .catch(() => {
            this.client.address_street = '';
            this.client.address_neighborhood = '';
            this.client.address_city = '';
            this.client.address_state = '';
            this.addressField.block = false;
            this.addressField.loader = false;
          });
      },
    async fetchClients() {
      const response = await this.get(`/client?page=${this.page}`);
      if (response) {
        this.clients = response.clients;
        this.totalPages = response.pages;
      }
    },
    openDialog(client = null) {
      this.dialog = true;
      if (client) {
        this.editingClient = client.id;
        this.client = { ...client };
      } else {
        this.resetClientForm();
      }
    },
    closeDialog() {
      this.dialog = false;
      this.resetClientForm();
    },
    resetClientForm() {
      this.editingClient = null;
      this.client = this.getEmptyClient();
    },
    async saveClient() {
      if (!this.$refs.clientForm.validate()) return;

      const endpoint = this.editingClient ? `/client/${this.editingClient}/update` : "/client";
      const response = await this.post(endpoint, this.client);

      if (response.client) {
        if(this.editingClient===null){
          this.clients.unshift(response.client);
          this.clientView = {
            client: response.client,
            signatures: [],
            charges: []
          }
        } else {
            let index = this.clients.findIndex(cli => cli.id === response.client.id);
            if (index > -1) this.$set(this.clients, index, response.client);
            if(this.clientView.client)
              this.$set(this.clientView, 'client', response.client);
        }
        //this.fetchClients();
        this.closeDialog();
      }
    },
    getEmptyClient() {
      return {
        name: '',
        lastname: '',
        document: '',
        birthdate: '',
        email: '',
        phone: '',
        postal_code: '',
        address_street: '',
        address_number: '',
        address_complement: '',
        address_neighborhood: '',
        address_city: '',
        address_state: '',
      };
    },
    async openItem(item){
      let response = await this.get("/app/client/"+item.id+"?charges=true&signatures=true");
      if(response.client){
        this.$emit('navigateTo','/cliente/'+response.client.id);
        this.clientView = response;
      }
    },
  },
  mounted() {
    this.fetchClients();
  },
};
</script>
